/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@media (max-width: 900px) {

body{

    border-top: 16px solid #777167;
    border-bottom: 16px solid #777167;
    height: auto;
    min-height: 800px !important;
/* maybe #eceff1 */
}

 
.custom-modalbox .mat-dialog-container{
    padding: 0px !important;
  }

}
html, body { min-height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
